.Store {
    margin: 2rem auto;
    width: 90vw;
}

.Store > h1 {
    text-align: center;
    color: rgb(243, 59, 129);
    font-weight: bolder;
    border-bottom: yellow 0.5px dashed;
}
.navbar{
    background-color: rgb(90, 56, 241);
    border-radius: 30px;
    
}
.navbar ul{
    overflow: auto;
}
.navbar li{
    float:left;
    list-style: none; 
    margin: 13px 20px;
    
}
.navbar li a{
    padding: 3px 3px;
    text-decoration: none;
    color: white;
}
.navbar li a:hover{
    color: red
}
.search{
    float: right;
    color: white;
    padding: 12px 75px;
}
.navbar input{
    border: 2px solid black;
    border-radius: 14px;
    padding: 3px 17px;
    width: 129px;
}